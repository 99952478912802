import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Here will be the YAMAKO TRIO website, more information coming soon...
    </p>
    </header>
    </div>
);
}

export default App;
